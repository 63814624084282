<template>
    <div class="content">
        <transition name="fade">
            <div class="content-card" v-if="!sended">
                <div class="content__title text_40_b">Договор-оферта</div>
                <p>
                    Настоящий договор-оферта (далее – Договор) является предложением Общества с ограниченной
                    ответственностью «Арлан-Авто» ОГРН 1156313032688, ИНН 6321388467, именуемого в дальнейшем
                    «Исполнитель» на оказание услуг по осмотру транспортного средства с пробегом.
                </p>
                <p class="h2">1. Порядок заключения договора</p>
                <p>
                    1.1. Посетитель сайта «a-a63.ru» вправе заказать у Исполнителя услугу по проверке и осмотру
                    транспортного средства с пробегом (далее – услуга).
                </p>
                <p>1.2. Транспортное средство должно находиться на территории Самарской области.</p>
                <p>
                    1.3. Для заключения договора посетитель сайта «a-a63.ru» (далее – Заказчик) должен выбрать тариф,
                    кликнуть на кнопку «Купить» (или иную схожую по смыслу), расположенную рядом с разделом «Тарифы»,
                    заполнить предлагаемую форму заявки и оплатить аванс за заказанную услугу в размере 100 % от
                    стоимости выбранного тарифа, предусмотренным настоящим Договором.
                </p>
                <p>
                    1.4. Делая заказ и оплачивая услугу, Заказчик совершает акцепт, т.е. полностью и безоговорочно
                    принимает все условия настоящего Договора. Делая заказ и оплачивая услугу, Заказчик гарантирует, что
                    ознакомился со всеми условиями настоящего Договора, ему понятны содержание и порядок оказания услуги
                    и он полностью согласен с ними. Заказчик также гарантирует, что заключает настоящий Договор
                    добровольно и в своем интересе.
                </p>
                <p>
                    1.5. Делая заказ и оплачивая аванс за услугу, Заказчик соглашается и полностью принимает Политику
                    конфиденциальности, дает согласие на обработку персональных данных ООО «Арлан-Авто», расположенных
                    по ссылке
                    <a href="https://arlan-auto.ru/informaciya?tab=4&subTab=4" target="_blank"
                        >https://arlan-auto.ru/informaciya?tab=4&subTab=4</a
                    >. Заказчик гарантирует, что ознакомился с указанной Политикой и порядком обработки персональных
                    данных.
                </p>
                <p>
                    1.6. Заказчик уведомлен, что ООО «Арлан-Авто», предоставляющее сервис «a-a63.ru», не является,
                    организатором, поверенным, комиссионером и/или агентом Продавца транспортного средства и не несет
                    никакой ответственности по обязательствам, вытекающим из сделок, заключенных Заказчиком с Продавцом
                    транспортного средства. Заказчик заключает Договор непосредственно с Продавцом транспортного
                    средства без участия ООО «Арлан-Авто».
                </p>
                <p>
                    1.7. Способ коммуникации при оказании услуги и способ направления фото-видеоотчета, акта осмотра,
                    чек-листа: - по номеру телефона Заказчика, указанному при заказе и оплате услуги на сайте a-a63.ru,
                    а также через мобильное приложение типа мессенджер с аккаунтом, привязанным к данному номеру
                    телефона. - по электронной почте, если ее адрес был сообщен и определен Заказчиком в Заявке как
                    способ коммуникации и направления отчета.
                </p>
                <p class="h2">2. Предмет договора</p>
                <p>
                    2.1. По договору Исполнитель обязуется оказать Заказчику услуги по осмотру транспортного средства с
                    пробегом (далее – ТС, автомобиль), указанного в Заявке Заказчика (Приложение № 1 к настоящему
                    договору) (далее – Заявка), а Заказчик обязуется оплатить эти услуги.
                </p>
                <p>
                    2.2. Исполнитель оказывает Заказчику в объеме, соответствующем заказанному Заказчику набору услуг
                    (далее – Пакет услуг). Пакет услуг указывается Заказчиком в Заявке, заполняемой на сайте «a-a63.ru»
                    при заказе услуги.
                </p>
                <p>2.3. По настоящему договору возможно оказание следующих Пакетов услуг:</p>
                <p>
                    2.3.1. Комплект «Express»: проверка лакокрасочного покрытия, визуальная диагностика состояния
                    автомобиля
                </p>
                <p>
                    2.3.2. Комплект «Standart»: проверка ЛКП (все навесные детали, весь кузов включая лонжерон , стойки
                    и тд.)
                </p>
                <p>
                    2.3.3. Комплект «Full Check»: проверка ЛКП (все навесные детали, весь кузов включая лонжерон ,
                    стойки и тд.), проверка всех креплений , болтов , соединений сварки ).
                </p>
                <p>2.3.4 Проверка эндоскопом ДВС: Автомобили марки «ВАЗ, Иностранные транспортные средства</p>
                <p>2.3.5 Диагностика с подключением</p>
                <p>2.3.6 Удаленный осмотр ТС (на территории Самарской области) комплект «Express»</p>
                <p>2.3.7 Удаленный осмотр ТС (на территории Самарской области) комплект «Standart</p>
                <p class="h2">3. Срок и порядок оказания услуг</p>
                <p>
                    3.1. Предельный срок оказания услуг составляет 5 рабочих дней и исчисляется с даты оплаты.
                    Исполнитель имеет право оказать услуги досрочно.
                </p>
                <p>
                    3.2. Стороны соглашаются, что сроки осмотра ТС по определенным параметрам зависят от воли третьих
                    лиц, то есть от имеющихся обстоятельств, находящихся вне контроля Исполнителя, и не могут быть
                    гарантированы Исполнителем.
                </p>
                <p>
                    3.3. Услуги по настоящему договору считаются оказанными Исполнителем считаются полностью
                    исполненными по факту предоставления Заказчику фото-видеоотчета, акта осмотра и чек-листа, по
                    формам, установленным Исполнителем.
                </p>
                <p>
                    3.4. По окончании оказания услуг Исполнитель направляет Заказчику для подписания акт об оказании
                    услуг (Приложение № 2). По истечении 2 дней со дня получения акта, в случае отсутствия обоснованных
                    возражений, акт считается подписанным Заказчиком.
                </p>
                <p class="h2">4. Порядок оплаты</p>
                <p>4.1. Цена оказания услуг по настоящему договору составляет:</p>
                <p>- Комплект «Express» - 1000 (Одна тысяча) рублей, НДС не облагается;</p>
                <p>- Комплект «Standart» - 5000 (Пять тысяч) рублей, НДС не облагается;</p>
                <p>- Комплект «Full Check» - 6000 (Шесть тысяч) рублей, НДС не облагается.</p>
                <p>
                    - Проверка эндоскопом ДВС: Автомобили марки «ВАЗ» - 700 (семьсот) рублей, НДС не облагается,
                    Иностранные транспортные средства – 2 000 (две тысячи) рублей НДС не облагается;
                </p>
                <p>- Диагностика с подключением – 500 (пятьсот) рублей НДС не облагается;</p>
                <p>
                    - Удаленный осмотр ТС (на территории Самарской области) комплект «Express» - 2 000(две тысячи)
                    рублей, НДС не облагается;
                </p>
                <p>
                    - Удаленный осмотр ТС (на территории Самарской области) комплект «Standart» - 6 000 (шесть тысяч)
                    рублей, НДС не облагается;
                </p>
                <p>
                    Стоимость услуг может быть изменена Исполнителем в одностороннем порядке путем размещения новой
                    редакции настоящего договора-оферты по адресу a-a63.ru.
                </p>
                <p>
                    4.2. Заказчик оплачивает аванс в размере 100% от выбранного тарифа за услугу при ее заказе
                    (оформлении заявки) на сайте «a-a63.ru». С момента оплаты аванса стоимость оплаченной услуги
                    изменению не подлежит.
                </p>
                <p>
                    4.3. В случае, если по объективным причинам окажется невозможным оказать услугу по осмотру ТС в
                    соответствии с условиями Заявки, Исполнитель обязан известить об этом Заказчика и произвести возврат
                    денежных средств, переданных Исполнителю.
                </p>
                <p>
                    4.4. В случае, если Заказчик немотивированно отказался от исполнения настоящего Договора на любой
                    стадии его исполнения, Исполнитель вправе удержать денежные средства, полученные от Заказчика, в
                    качестве штрафа.
                </p>
                <p class="h2">5. Уведомления и обмен сообщениями</p>
                <p>
                    5.1. Стороны договорились, что для обмена уведомлениями и иными юридически значимыми
                    сообщениями/документами в рамках исполнения настоящего Договора они будут пользоваться электронными
                    каналами связи, в том числе электронной почтой и онлайн-мессенджером WhatsApp или другим
                    мессенджером по согласованию Сторон, используя контактные данные, указанные в настоящем Договоре и
                    Заявке.
                </p>
                <p>
                    5.2. Стороны признают юридическую силу электронных сообщений и документов, направленных по
                    электронной почте (e-mail) или с использованием аккаунтов в онлайн-мессенджерах.
                </p>
                <p>
                    5.3. Только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам
                    электронной почты и аккаунтам в онлайн-мессенджерах. Доступ к указанным каналам связи каждая Сторона
                    осуществляет по паролю и обязуется сохранять его конфиденциальность.
                </p>
                <p class="h2">6. Ответственность сторон</p>
                <p>
                    6.1. Все споры и разногласия, возникающие между Сторонами в связи с исполнением настоящего Договора,
                    разрешаются путём переговоров. Соблюдение претензионного порядка урегулирования споров из настоящего
                    Договора является обязательным. В случае направления претензии в рамках исполнения настоящего
                    Договора Сторона, получившая претензию, обязана предоставить ответ на неё в течение десяти
                    календарных дней с даты получения. Претензия может быть направлена почтовым отправлением или на
                    электронную почту по адресам: Исполнителя –
                    <a href="mailto:doc@arlan-avto.ru">doc@arlan-avto.ru</a>, Заказчика – согласно п. 1.7. Договора. В
                    случае невозможности вручения претензии по причинам, независящим от отправителя (отказ адресата от
                    вручения; неявка адресата для получения; иное), претензия считается вручённой надлежащим образом в
                    день доставки корреспонденции в место вручения
                </p>
                <p>
                    6.2. В случае, если по истечение срока, указанного в пункте 3.1 договора, услуга по осмотру ТС не
                    оказан, договор считается расторгнутым, а внесенный Заказчиком аванс возвращается. Иной
                    ответственности Исполнитель не несет.
                </p>
                <p class="h2">7. Прочие условия</p>
                <p>
                    7.1. Настоящий Договор заключен в порядке, предусмотренном разделом 1, и не требует подписания
                    сторонами на бумажном носителе.
                </p>
                <p class="h2">8. Реквизиты Исполнителя</p>
                <ul>
                    <li>ООО «Арлан Авто»</li>
                    <li>Юридический и почтовый адрес:</li>
                    <li>445044, Самарская обл., г.Тольятти, Южное шоссе 73, офис 1</li>
                    <li>ОГРН 1146320017271</li>
                    <li>ИНН 6321358825</li>
                    <li>КПП 632101001</li>
                    <li>ОКПО 33554860</li>
                    <li>Расчетный счет 40702810254400063511 в Поволжском банке ПАО СБЕРБАНК г.Самара</li>
                    <li>БИК 043601607</li>
                    <li>Корр. счет 30101810200000000607</li>
                    <li>Тел. рабочий: <a href="tel:8482207810">(8482) 207810</a></li>
                    <li>e-mail: <a href="mailto:doc@arlan-avto.ru">doc@arlan-avto.ru</a></li>
                </ul>
            </div>
        </transition>
        <form @submit.prevent="submit()" class="user-form">
            <transition name="fade" mode="out-in">
                <div class="user-form__content" key="2" v-if="sended">
                    <div class="text_24_b tac">
                        Вы подписали договор оферты, менеджер свяжется с вами в ближайшее время
                    </div>
                </div>
                <div class="user-form__content" v-else key="1">
                    <input type="hidden" v-model="form.lead_id" readonly />
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Укажите ФИО: <span class="error">{{ errorMessages.name }}</span>
                            </div>
                            <div class="form-field__component">
                                <input
                                    class="form-group__input"
                                    type="text"
                                    required
                                    disabled
                                    readonly
                                    v-model="form.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">Укажите ваш номер телефона:</div>
                            <div class="form-field__component">
                                <input
                                    class="form-group__input"
                                    type="text"
                                    required
                                    disabled
                                    readonly
                                    v-model="form.phone"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <label class="flex-c">
                                <input type="checkbox" name="agree" v-model="form.agree" />
                                <span>Согласен с условиями договора оферты</span>
                            </label>
                            <div class="error">{{ errorMessages.agree }}</div>
                        </div>
                    </div>
                    <div class="flex">
                        <button class="button" type="submit" :disabled="!form.agree">
                            Подписать договор
                            <div class="loader" v-if="requestStatus === 'loading'"></div>
                        </button>
                    </div>
                    <div class="text_24_b tac submit-error" v-if="requestStatus === 'error'">
                        Произошла ошибка отправки формы, <br />позвоните по номеру телефона
                        <a href="tel:88482613813">8 (8482) 613 - 813</a>
                    </div>
                </div>
            </transition>
        </form>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "Oferta",
    data() {
        const id = this.$route.query.lead_id;
        return {
            sended: false,
            requestStatus: "",
            errorMessages: {
                name: "",
            },
            form: {
                name: "",
                phone: "",
                agree: false,
                lead_id: id,
            },
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
        };
    },
    methods: {
        showError(field) {
            let message = "заполните это поле";
            if (field == "name") {
                message = "заполните имя";
            }
            if (field == "agree") {
                message = "необходимо согласться с условиями оферты";
            }
            this.errorMessages[field] = message;
        },
        async submit() {
            if (!this.form.name) {
                this.showError("name");
                return false;
            }
            if (!this.form.agree) {
                this.showError("agree");
                return false;
            }

            this.requestStatus = "loading";
            axios.defaults.withCredentials = true;
            axios
                .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                .then(() => {
                    axios
                        .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/salon/car-selection", {
                            phone: this.form.phone,
                            name: this.form.name,
                            lead_id: this.form.lead_id,
                        })
                        .then(() => {
                            this.sended = true;
                            this.requestStatus = "";
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
    created() {
        axios
            .get("https://api.arlan-auto.ru/api/v1/csrf_token")
            .then(() => {
                axios
                    .get("https://api.arlan-auto.ru/api/v1/amo/get/lead/salon", {
                        params: { lead_id: this.form.lead_id },
                    })
                    .then((res) => {
                        this.form.name = res.data.data.name;
                        this.form.phone = res.data.data.phone;
                    });
            })
            .catch((e) => {
                console.log(`e =>`, e);
                this.requestStatus = "error";
            });
    },
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
.h2 {
    font-weight: 700;
    margin-top: 36px;
    margin-bottom: 12px;
}
ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    li {
        margin-bottom: 2px;
    }
}
.content {
    &__title {
        text-align: center;
    }
}
</style>
